
import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: 'Main',
    main: [
      {
        state: 'dashboard',
        name: 'Dashboard',
        type: 'link',
        icon: 'ti-home'
      },
      {
        state: 'leads',
        name: 'Landing Page Leads',
        type: 'link',
        icon: 'ti-home'
      },
      {
        state: 'banners',
        name: 'Banner',
        type: 'link',
        icon: 'ti-image'
      }, 
      {
        state: 'home-panel',
        name: 'Home-Panel',
        type: 'link',
        icon: 'ti-image'
      }, 
      {
        state: 'articles',
        name: 'Articles Landing Page',
        type: 'link',
        icon: 'ti-notepad'
      }, 
      {
        state: 'resources',
        name: 'Image Resource',
        type: 'link',
        icon: 'ti-image'
      },
      
      /* {
        state: 'book-now',
        name: 'Book Now',
        type: 'link',
        icon: 'ti-book'
      }, */ 
      
      /* {
        state: 'related-package',
        name: 'Related Packages',
        type: 'link',
        icon: 'ti-package'
      },
      */

      {
        state: 'city',
        name: 'Available Cities',
        type: 'link',
        icon: 'ti-location-pin'
      }, 
      {
        state: 'orders',
        name: 'Orders',
        type: 'link',
        icon: 'ti-layout-grid2'
      },
      /* {
        state: 'transaction',
        name: 'Transaction',
        type: 'link',
        icon: 'ti-envelope'
      }, */
      {
        state: 'users',
        name: 'Users',
        type: 'link',
        icon: 'ti-user'
      },
      {
        state: 'offers',
        name: 'Coupons',
        type: 'link',
        icon: 'ti-medall'
      },  
      /* {
        state: 'payment',
        name: 'Payments',
        type: 'link',
        icon: 'ti-money'
      } */
    ],
  },
  {
    label: 'Manage',
    main: [
      /* {
        state: 'languages',
        name: 'Languages',
        type: 'link',
        icon: 'ti-world'
      }, 
      {
        state: 'banners',
        name: 'Banners',
        type: 'link',
        icon: 'ti-layout-list-large-image',
      }, 
      {
        state: 'viewmessage',
        name: 'Contact Messages',
        type: 'link',
        icon: 'ti-layout-list-large-image',
      },
        {
        state: 'blogs',
        name: 'Blogs',
        type: 'link',
        icon: 'ti-book',
      },
      
      {
        state: 'notifications',
        name: 'Notification',
        type: 'link',
        icon: 'ti-bell'
      },
        {
        state: 'contacts',
        name: 'Support',
        type: 'link',
        icon: 'ti-comments-smiley'
      }, */
      /* {
        state: 'emails',
        name: 'Contacts',
        type: 'link',
        icon: 'ti-email'
      },
      {
        state: 'stats',
        name: 'Store Stats',
        type: 'link',
        icon: 'ti-stats-up'
      },

      {
        state: 'driver-stats',
        name: 'Drivers Stats',
        type: 'link',
        icon: 'ti-stats-up'
      }, */

/*
      {
        state: 'manage-app',
        name: 'Manage App',
        type: 'link',
        icon: 'ti-lock'
      },
       {
        state: 'send-mail',
        name: 'Send Emails',
        type: 'link',
        icon: 'ti-email'
      }, 
      {
        state: 'app-settings',
        name: 'App Settings',
        type: 'link',
        icon: 'ti-desktop'
      }, 
      {
        state: 'general',
        name: 'General',
        type: 'link',
        icon: 'ti-settings'
      },
      /* {
        state: 'app-pages',
        name: 'App Pages',
        type: 'link',
        icon: 'ti-blackboard'
      },
      {
        state: 'manage-popup',
        name: 'Manage Popup',
        type: 'link',
        icon: 'ti-quote-right'
      }, */
      {
        state: 'administrantor',
        name: 'Administrator',
        type: 'link',
        icon: 'ti-id-badge'
      }

    ]
  },

];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }

  /*add(menu: Menu) {
    MENUITEMS.push(menu);
  }*/
}
