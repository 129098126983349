export const environment = {
  production: true,
  baseURL: 'https://powertekapi.i1web.com/index.php/',
  mediaURL: 'https://powertekapi.i1web.com/uploads/',
  onesignal: {
    appId: '',
    googleProjectNumber: '',
    restKey: ''
  },
  general: {
    symbol: 'Rs.',
    code: 'Rupee'
  },
  authToken: 'PtiTekcRMY_zsQwscnst32YhaubDxMaP', 
  default_country_code: '91'
};
  